
import {defineComponent} from "vue";
import {Conflict} from "@/models/user/Conflict";
import moment from "moment";
import {ConflictService} from "@/services/ConflictService";
import Swal from "sweetalert2";
import {bus} from "@/modules/eventBus";
import {ProblemDetails} from "@/models/ProblemDetails";
import {useToast} from "vue-toastification";
import {alertController, isPlatform, toastController} from "@ionic/vue";

export default defineComponent({
  setup() {
    const toast = useToast();
    return {toast}
  },
  data() {
    return {
      conflicts: [] as Array<Conflict>,
      isFetching: true
    }
  },
  mounted() {
    this.fetchConflicts()
    bus.on('modal:conflicts:added', (conflict) => {
      this.addConflictToList(conflict as Conflict)
    })
  },
  methods: {
    fetchConflicts() {
      this.isFetching = true
      ConflictService.list().then(response => {
        this.conflicts = response.data
      }).catch(err => {
        let errorDetails = err.response.data as ProblemDetails
        this.toast.error(errorDetails.detail)
      }).finally(() => {
        this.isFetching = false
      })
    },
    addConflictToList(conflict: Conflict) {
      this.conflicts.push(conflict)
    },
    async removeConflict(conflictId: string) {
      if(isPlatform('capacitor')){
        const alert = await alertController
            .create({
              header: 'Are you sure?',
              message: 'Removing this conflict',
              buttons: [
                {
                  text: 'Cancel',
                  role: 'cancel',
                  cssClass: 'secondary',
                  id: 'cancel-button',
                  handler: () => {},
                },
                {
                  text: 'Okay',
                  id: 'confirm-button',
                  handler: () => {
                    this.doRemoveConflict(conflictId)
                  },
                },
              ],
            });
        return alert.present();
      } else {
        Swal.fire({
          title: "Are you sure?",
          confirmButtonText: 'Remove Conflict',
          showConfirmButton: true,
          showCancelButton: true,
          cancelButtonText: 'Keep Conflict'
        }).then(response => {
          if (response.isConfirmed) {
            this.doRemoveConflict(conflictId)
          }
        })
      }
    },
    doRemoveConflict(conflictId: string){
      ConflictService.remove(conflictId).then(() => {
        if(isPlatform('capacitor')){
          toastController.create({
            message: 'Conflict Removed',
            duration: 2000
          }).then(toast => {
            toast.present()
          });
        } else {
          this.toast.success('Conflict Removed')
        }
        this.fetchConflicts()
      }).catch(err => {
        let errorDetails = err.response.data as ProblemDetails
        this.toast.error(errorDetails.detail)
      })
    },
    invokeAddConflict() {
      bus.emit('modal:conflicts:add')
    },
    invokeUpdateConflict(_conflict: Conflict) {
      bus.emit('modal:conflicts:update', _conflict)
    },
    formatDate(_conflict: Conflict): string {
      const startDate = moment(_conflict.start).format('MM/DD/YYYY')
      const endDate = moment(_conflict.end).format('MM/DD/YYYY')
      const isSameDay = startDate === endDate
      if (isSameDay) {
        return `${moment(_conflict.start).format('MMMM Do h:mm a')} - ${moment(_conflict.end).format('h:mm a')}`
      }
      return `${moment(_conflict.start).format('MMMM Do h:mm a')} - ${moment(_conflict.end).format('MMMM Do h:mm a')}`
    },
  },
  computed: {
    upcomingConflicts(): Array<Conflict> {
      return this.conflicts.filter(c => {
        return moment(c.start).isAfter(moment().subtract(3, "days").endOf('day'))
      })
    },
  }
})
